body {
    margin: 0;
    font-family: "Work Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    color: #828282;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: 200;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
    color: #000;
    font-family: "Work Sans", Arial, sans-serif;
    font-weight: 400;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}