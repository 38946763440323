@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700&display=swap");
/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_extend.scss */
.flex-center-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}

/* Normal desktop :1200px. */
/* Normal desktop :992px. */
/* Tablet desktop :768px. */
/* small mobile :320px. */
/* Large Mobile :480px. */
/* 1. Theme default css */
/* line 5, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  scroll-behavior: smooth;
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.img {
  max-width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 16, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
a,
.button {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
a:focus,
.button:focus, button:focus {
  text-decoration: none;
  outline: none;
}

/* line 25, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
a:focus {
  text-decoration: none;
}

/* line 28, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  text-decoration: none;
}

/* line 34, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
a,
button {
  color: #1F1F1F;
  outline: medium none;
}

/* line 39, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
h1, h2, h3, h4, h5 {
  font-family: "Poppins", sans-serif;
  color: #001D38;
}

/* line 43, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

/* line 52, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
ul {
  margin: 0px;
  padding: 0px;
}

/* line 56, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
li {
  list-style: none;
}

/* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #4D4D4D;
  margin-bottom: 13px;
  font-family: "Poppins", sans-serif;
}

/* line 68, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

/* line 74, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
*::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 79, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 84, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 89, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
*::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 94, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
*:-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 99, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
*::-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 104, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
*::placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 110, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
h3 {
  font-size: 24px;
}

/* line 114, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.mb-65 {
  margin-bottom: 67px;
}

/* line 118, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.black-bg {
  background: #020c26 !important;
}

/* line 122, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.white-bg {
  background: #ffffff;
}

/* line 125, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.gray-bg {
  background: #f5f5f5;
}

/* line 130, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.bg-img-1 {
  /* background-image: url(./img/slider/slider-img-1.jpg); */
}

/* line 133, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.bg-img-2 {
  /* background-image: url(./img/background-img/bg-img-2.jpg); */
}

/* line 136, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.cta-bg-1 {
  /* background-image: url(./img/background-img/bg-img-3.jpg); */
}

/* line 141, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.overlay {
  position: relative;
  z-index: 0;
}

/* line 145, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.overlay::before {
  position: absolute;
  content: "";
  background-color: #001D38;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .7;
}

/* line 157, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.overlay2 {
  position: relative;
  z-index: 0;
}

/* line 161, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.overlay2::before {
  position: absolute;
  content: "";
  background-color: #001D38;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;
}

/* line 173, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.overlay_03 {
  position: relative;
  z-index: 0;
}

/* line 177, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.overlay_03::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #001D38;
  opacity: .6;
  content: '';
  z-index: -1;
}

/* line 190, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.bradcam_overlay {
  position: relative;
  z-index: 0;
}

/* line 194, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.bradcam_overlay::before {
  position: absolute;
  content: "";
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5db2ff+0,7db9e8+100&1+24,0+96 */
  background: -moz-linear-gradient(left, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5db2ff', endColorstr='#007db9e8',GradientType=1 );
  /* IE6-9 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
}

/* line 210, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

/* line 214, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.pt-120 {
  padding-top: 120px;
}

/* button style */
/* line 220, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div {
  background: transparent;
  height: 40px;
  left: 0px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 40px;
  background-color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 40px;
  font-size: 12px;
  line-height: 40px;
  background: #FFFFFF;
  left: -20px;
  color: #FF3414 !important;
}

/* line 247, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: -20px;
}

/* line 252, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-next i {
  position: relative;
  right: 0;
}

/* line 259, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-prev i {
  position: relative;
  top: 0px;
}

/* line 269, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

/* line 272, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.owl-carousel:hover .owl-nav div:hover {
  color: #fff !important;
  background: #FF3414;
}

/* line 281, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.mb-20px {
  margin-bottom: 20px;
}

/* line 285, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.mb-55 {
  margin-bottom: 55px;
}

/* line 288, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.mb-40 {
  margin-bottom: 40px;
}

/* line 291, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.mb-20 {
  margin-bottom: 20px;
}

/* line 295, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.mb-50 {
  margin-bottom: 50px;
}

/* line 298, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.mb-60 {
  margin-bottom: 50px !important;
}

/* line 302, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #000;
}

/* line 314, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.custom_search_pop .modal-content .serch_form {
  position: relative;
}

/* line 316, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.custom_search_pop .modal-content .serch_form input {
  width: 100%;
  height: 50px;
  background: #fff;
  color: #fff;
  border: none;
  padding-left: 20px;
  color: #000;
  font-size: 15px;
  padding-right: 120px;
}

/* line 326, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.custom_search_pop .modal-content .serch_form input:placeholder {
  color: #000;
  font-size: 15px;
}

/* line 330, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.custom_search_pop .modal-content .serch_form input:focus {
  outline: none;
}

/* line 334, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/theme-default.scss */
.custom_search_pop .modal-content .serch_form button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  height: 100%;
  padding: 20px;
  color: #fff;
  background: #FF3414;
  display: inline-block;
  border: none;
  line-height: 50px;
  height: 50px;
  padding: 0 30px;
  cursor: pointer;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn {
  background: #fff;
  color: #131313;
  display: inline-block;
  padding: 18px 44px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border: 1px solid #FF3414;
  letter-spacing: 3px;
  text-align: center;
  color: #FF3414 !important;
  text-transform: uppercase;
  cursor: pointer;
}

/* line 17, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn:hover {
  background: #FF3414;
  color: #fff !important;
  border: 1px solid #FF3414;
}

/* line 22, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn:focus {
  outline: none;
}

/* line 25, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn.large-width {
  width: 220px;
}

/* line 29, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3 {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#FF3414+0,fd8e5e+100 */
  background: #FF3414;
  color: #fff;
  display: inline-block;
  padding: 18px 42px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border: 1px solid transparent;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

/* line 47, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#FF3414+0,fd8e5e+100 */
  color: #FF3414 !important;
  border: 1px solid #FF3414;
  background: transparent;
}

/* line 54, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3:focus {
  outline: none;
}

/* line 57, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3.large-width {
  width: 220px;
}

/* line 62, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-white {
  color: #fff;
  display: inline-block;
  padding: 13px 27px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border: 1px solid #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  letter-spacing: 2px;
}

/* line 79, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-white:hover {
  background: #28AE61;
  color: #fff !important;
  border: 1px solid transparent;
}

/* line 84, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-white i {
  margin-right: 2px;
}

/* line 87, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-white:focus {
  outline: none;
}

/* line 90, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-white.large-width {
  width: 220px;
}

/* line 95, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-line {
  color: #FF3414 !important;
  display: inline-block;
  padding: 14px 31px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: 0;
  border: 1px solid #FF3414;
  text-align: center;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  background: transparent;
}

/* line 110, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-line:hover {
  color: #fff !important;
  border: 1px solid transparent;
  background: #FF3414;
}

/* line 115, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-line:focus {
  outline: none;
}

/* line 118, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn3-line.large-width {
  width: 220px;
}

/* line 122, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn2 {
  background: transparent;
  color: #fff;
  display: inline-block;
  padding: 18px 24px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border: 1px solid #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* line 134, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn2:hover {
  background: #fff;
  color: #131313 !important;
}

/* line 138, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.boxed-btn2:focus {
  outline: none;
}

/* line 142, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.line-button {
  color: #919191;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  padding-right: 5px;
  padding-bottom: 2px;
}

/* line 150, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.line-button::before {
  position: absolute;
  content: "";
  background: #919191;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
}

/* line 159, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.line-button:hover {
  color: #009DFF;
}

/* line 162, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.line-button:hover::before {
  background: #009DFF;
}

/* line 166, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.book_now {
  display: inline-block;
  font-size: 14px;
  color: #009DFF;
  border: 1px solid #009DFF;
  text-transform: capitalize;
  padding: 10px 25px;
}

/* line 173, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_btn.scss */
.book_now:hover {
  background: #009DFF;
  color: #fff;
}

/* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
.section_title h3 {
  font-size: 46px;
  font-weight: 400;
  line-height: 52px;
  color: #001D38;
  position: relative;
  z-index: 0;
}

@media (max-width: 767px) {
  /* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
  .section_title h3 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
  .section_title h3 {
    font-size: 36px;
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  /* line 18, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
  .section_title h3 br {
    display: none;
  }
}

/* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
.section_title p {
  font-size: 16px;
  color: #727272;
  line-height: 28px;
  margin-bottom: 0;
  font-weight: 400;
}

@media (max-width: 767px) {
  /* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
  .section_title p br {
    display: none;
  }
}

/* line 38, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
.section_title h4 {
  font-size: 22px;
  font-weight: 500;
  color: #001D38;
  padding-bottom: 21px;
}

/* line 44, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
.mb-50 {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  /* line 44, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_section_title.scss */
  .mb-50 {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  /* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
  .mobile_menu {
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: 9;
  }
}

/* line 13, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav {
  background: #fff;
  float: right;
  margin-top: 0;
  padding: 0;
  width: 95%;
  padding: 0;
  border-radius: 0px;
  margin-top: 5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 11px;
}

/* line 28, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav a:hover {
  background: transparent;
  color: #FF3414;
}

/* line 32, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav a.active {
  color: #FF3414;
}

@media (max-width: 767px) {
  /* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
  .slicknav_menu .slicknav_nav a i {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
  .slicknav_menu .slicknav_nav a i {
    display: none;
  }
}

/* line 44, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav .slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: -40px;
  position: relative;
  z-index: 99;
  border: 1px solid #ddd;
  top: 3px;
  right: 5px;
  top: -36px;
}

/* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav .slicknav_btn .slicknav_icon {
  margin-right: 6px;
  margin-top: 3px;
  position: relative;
  padding-bottom: 3px;
  top: -11px;
  right: -5px;
}

@media (max-width: 767px) {
  /* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
  .slicknav_menu {
    margin-right: 0px;
  }
}

/* line 72, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_nav .slicknav_arrow {
  float: right;
  font-size: 22px;
  position: relative;
  top: -9px;
}

/* line 78, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  border: none;
  border-radius: 3px;
  top: 5px;
  padding: 5px;
  right: 5px;
  margin-top: -5px;
  top: -31px;
}

/* line 92, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  border: none;
  border-radius: 3px;
  top: 5px;
  padding: 5px;
  right: 0;
  margin-top: -5px;
  top: -33px;
}

/* line 107, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slick-nav.scss */
.slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  border: none;
  border-radius: 3px;
  top: 5px;
  padding: 5px;
  right: 6px;
  margin-top: -5px;
  top: -31px;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area {
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 9;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area {
    padding-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area {
    padding-top: 0;
  }
}

/* line 17, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area {
  padding: 7px 0;
  background: #fff;
}

@media (max-width: 767px) {
  /* line 17, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area {
    padding: 10px 0;
  }
}

/* line 23, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area.details_nav_bg {
  background: #727272;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  /* line 23, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area.details_nav_bg {
    padding-bottom: 10px;
  }
}

/* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area.details_nav {
  background: #001D38;
}

/* line 47, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .logo-img {
  text-align: center;
}

@media (max-width: 767px) {
  /* line 47, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img {
    text-align: left;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 47, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img {
    text-align: left;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 47, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img {
    text-align: left;
  }
}

@media (max-width: 767px) {
  /* line 61, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img img {
    width: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 61, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img img {
    width: 100px;
  }
}

/* line 73, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 77, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .search_button {
    margin-right: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 77, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .search_button {
    margin-right: 15px;
  }
}

/* line 86, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .search_button a i {
  color: #E8E8E8;
}

/* line 93, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li {
  display: inline-block;
}

/* line 96, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li a {
  color: #A8A7A0;
  margin: 0 10px;
  font-size: 15px;
}

/* line 100, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li a:hover {
  color: #fff;
}

/* line 107, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .book_btn {
  margin-left: 30px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 107, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .book_btn {
    margin-left: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 107, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .book_btn {
    margin-left: 0;
  }
}

/* line 115, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .book_btn a {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#FF3414+0,fd8e5e+100 */
  background: #fdae5c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fdae5c 0%, #fd8e5e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fdae5c 0%, #fd8e5e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fdae5c 0%, #fd8e5e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF3414', endColorstr='#fd8e5e',GradientType=0 );
  /* IE6-9 */
  padding: 13px 26px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid transparent;
  color: #fff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 115, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .book_btn a {
    padding: 12px 20px;
  }
}

/* line 133, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .book_btn a:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#FF3414+0,fd8e5e+100 */
  background: #fdae5c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fdae5c 0%, #fd8e5e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fdae5c 0%, #fd8e5e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fdae5c 0%, #fd8e5e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF3414', endColorstr='#fd8e5e',GradientType=0 );
  /* IE6-9 */
}

/* line 145, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu {
  padding: 12px 0;
}

/* line 148, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li {
  display: inline-block;
  position: relative;
  margin: 0 44px 0 0;
}

/* line 158, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li a {
  color: #596672;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  display: inline-block;
  padding: 0px 0px 0px 0px;
  font-family: "Poppins", sans-serif;
  position: relative;
  text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 158, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a {
    font-size: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 158, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a {
    font-size: 15px;
  }
}

/* line 177, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li a i {
  font-size: 9px;
}

@media (max-width: 767px) {
  /* line 177, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a i {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 177, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a i {
    display: none !important;
  }
}

/* line 214, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li a:hover {
  color: #FF3414;
}

/* line 218, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 160%;
  background: #fff;
  width: 200px;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  opacity: 0;
  visibility: hidden;
  text-align: left;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

/* line 230, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li {
  display: block;
}

/* line 232, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li a {
  padding: 10px 15px;
  position: inherit;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: block;
  color: #000;
}

/* line 238, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li a::before {
  display: none;
}

/* line 242, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li:hover a {
  color: #000;
}

/* line 247, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  top: 150%;
}

/* line 252, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:hover > a::before {
  opacity: 1;
  transform: scaleX(1);
}

/* line 256, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:first-child a {
  padding-left: 0;
}

/* line 262, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area.sticky {
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 990;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.96);
  background: #fff;
}

@media (max-width: 767px) {
  /* line 262, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area.sticky {
    padding: 10px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 262, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .main-header-area.sticky {
    padding: 10px 0px;
  }
}

/* line 290, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .main-menu {
  padding: 0;
}

/* line 293, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .header_bottom_border {
  border-bottom: none;
}

/* line 296, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .header_bottom_border.white_border {
  border-bottom: none !important;
}

/* line 320, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area {
  padding: 17px 0;
  background: #fff;
  border-bottom: 1px solid #EAEAEA;
}

@media (max-width: 767px) {
  /* line 324, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .header-top_area .social_media_links {
    text-align: center;
  }
}

/* line 328, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area .social_media_links a {
  font-size: 15px;
  color: #C7C7C7;
  margin-right: 12px;
}

/* line 332, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area .social_media_links a:hover {
  color: #FF3414;
}

/* line 338, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area .header_left p {
  color: #727272;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0;
}

/* line 345, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 345, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .header-top_area .short_contact_list {
    text-align: center;
  }
}

/* line 351, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li {
  display: inline-block;
}

/* line 353, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li a {
  font-size: 13px;
  color: #919191;
  margin-right: 50px;
}

@media (max-width: 767px) {
  /* line 353, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
  .header-area .header-top_area .short_contact_list ul li a {
    margin-left: 0;
    margin: 0 5px;
  }
}

/* line 362, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li a i {
  color: #FF3414;
  margin-right: 7px;
}

/* line 370, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .header-top_area .header_right.d-flex {
  display: flex;
  justify-content: flex-end;
}

/* line 379, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_header.scss */
.header-area .search_btn a {
  width: 35px;
  height: 35px;
  background: #FF3414;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  line-height: 35px;
  display: inline-block;
  color: #fff;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_bg_1 {
  /* background-image: url(./img/banner/banner.png); */
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_bg_2 {
  /* background-image: url(./img/banner/banner_2.png); */
}

/* line 7, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area {
  position: relative;
  z-index: 0;
}

/* line 10, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area::before {
  background: #001D38;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  opacity: .5;
}

/* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider {
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* line 25, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider.single_slider2 {
  height: 450px;
}

@media (max-width: 767px) {
  /* line 25, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider.single_slider2 {
    height: auto;
  }
}

/* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider.single_slider2 .property_wrap {
  position: relative;
  top: 166px;
}

@media (max-width: 767px) {
  /* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider.single_slider2 .property_wrap {
    top: 0;
  }
}

/* line 37, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider.single_slider2 .property_wrap .slider_text h3 {
  font-size: 60px;
  line-height: 80px;
  margin-bottom: 51px;
}

/* line 43, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider.single_slider2 .property_wrap .property_form {
  background: #001D38;
}

@media (max-width: 767px) {
  /* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider {
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider {
    height: 600px;
  }
}

@media (max-width: 767px) {
  /* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text {
    margin: 90px 0;
  }
}

/* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text h3 {
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  text-transform: capitalize;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 70px;
  margin-bottom: 30px;
  color: #fff;
  margin-top: 31px;
}

/* line 69, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text h3 span {
  font-weight: 700;
}

@media (max-width: 767px) {
  /* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 30px;
    line-height: 45px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 54px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 35px;
  }
}

/* line 84, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text p {
  font-size: 20px;
  font-weight: 400;
  color: #FFF;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 84, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  /* line 84, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text p {
    font-size: 16px;
  }
}

/* line 95, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text .video_service_btn > a {
  margin-right: 15px;
}

@media (max-width: 767px) {
  /* line 95, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text .video_service_btn > a {
    margin-bottom: 20px;
  }
}

/* line 102, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single_slider .property_form {
  padding: 30px;
  background: rgba(0, 28, 56, 0.6);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

@media (max-width: 767px) {
  /* line 102, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .property_form {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  /* line 109, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .property_form .form_wrap {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 109, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single_slider .property_form .form_wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

/* line 120, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .serach_icon a {
  width: 45px;
  height: 45px;
  background: #FD8E5E;
  text-align: center;
  line-height: 45px;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
}

@media (max-width: 767px) {
  /* line 120, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .serach_icon a {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 120, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .serach_icon a {
    width: 50%;
  }
}

/* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .serach_icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
}

@media (max-width: 767px) {
  /* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .serach_icon {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .serach_icon {
    width: 50%;
    align-items: center;
    margin-top: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 149, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single-field {
    margin-bottom: 20px;
  }
}

/* line 153, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.range_slider {
  width: 100%;
}

/* line 155, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.range_slider #slider {
  margin: 0;
}

/* line 161, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field .nice-select .list {
  color: #000;
  border-radius: 0;
}

/* line 165, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.max_width {
  margin-right: 24px;
}

@media (max-width: 767px) {
  /* line 165, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single-field.max_width {
    margin-right: 0;
  }
}

/* line 170, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.max_width label {
  display: block;
  font-size: 15px;
  color: #FDAE5C;
}

/* line 176, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.max_width .nice-select.wide {
  width: 166px;
  background: transparent;
  color: #C7C7C7;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid rgba(255, 255, 255, 0.4);
  height: 45px;
  line-height: 45px;
}

@media (max-width: 767px) {
  /* line 176, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single-field.max_width .nice-select.wide {
    width: 100%;
    margin-bottom: 15px;
  }
}

/* line 190, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.max_width .nice-select::after {
  content: "\e64b";
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 30px;
  top: 8px;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: 'themify';
  color: #ddd;
  color: #FFFFFF;
  top: 5px;
  font-size: 12px;
}

/* line 208, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.min_width {
  margin-right: 24px;
}

@media (max-width: 767px) {
  /* line 208, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single-field.min_width {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

/* line 214, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.min_width label {
  display: block;
  font-size: 15px;
  color: #FDAE5C;
}

/* line 220, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.min_width .nice-select.wide {
  width: 110px;
  background: transparent;
  color: #C7C7C7;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid rgba(255, 255, 255, 0.4);
  height: 45px;
  line-height: 45px;
}

@media (max-width: 767px) {
  /* line 220, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
  .slider_area .single-field.min_width .nice-select.wide {
    width: 100%;
    margin-bottom: 15px;
  }
}

/* line 234, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.slider_area .single-field.min_width .nice-select::after {
  content: "\e64b";
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 30px;
  top: 8px;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: 'themify';
  color: #ddd;
  color: #FFFFFF;
  top: 5px;
  font-size: 12px;
}

/* line 254, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_slider.scss */
.property_bg {
  /* background-image: url(./img/banner/property.png); */
}

/* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_info_area {
  padding-bottom: 70px;
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_info_area.plus_padding {
  padding-top: 100px;
}

@media (max-width: 767px) {
  /* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
  .about_info_area.plus_padding {
    padding-top: 30px;
  }
}

/* line 10, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_info_area .about_text {
  margin-bottom: 30px;
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_info_area .about_text h3 {
  font-size: 46px;
  font-weight: 300;
  color: #001D38;
}

@media (max-width: 767px) {
  /* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
  .about_info_area .about_text h3 {
    font-size: 30px;
  }
}

/* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_info_area .about_text p {
  font-size: 15px;
  font-weight: 400;
  color: #727272;
  margin-bottom: 24px;
  margin-top: 10px;
}

/* line 27, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_info_area .about_text a {
  padding: 13px 43px;
}

/* line 31, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_info_area .about_thumb {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}

/* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_info_area .about_thumb img {
  width: 100%;
}

/* line 43, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_mission {
  padding-top: 120px;
}

@media (max-width: 767px) {
  /* line 43, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
  .about_mission {
    padding-top: 80px;
  }
}

/* line 48, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_mission .about_thumb {
  margin-bottom: 20px;
}

/* line 50, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_mission .about_thumb img {
  width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

/* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_mission .about_text {
  margin-bottom: 20px;
  padding-left: 68px;
}

@media (max-width: 767px) {
  /* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
  .about_mission .about_text {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
  .about_mission .about_text {
    padding-left: 0;
  }
}

/* line 64, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_mission .about_text h4 {
  font-size: 42px;
  font-weight: 500;
  color: #001D38;
}

/* line 69, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_about.scss */
.about_mission .about_text p {
  font-size: 15px;
  font-weight: 400;
  color: #727272;
  line-height: 28px;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_counter.scss */
.counter_area {
  padding-bottom: 85px;
  padding-top: 12px;
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_counter.scss */
.counter_area .offcan_bg {
  /* background-image: url(./img/about/0ffcan.png); */
  background-size: 100% 100%;
  background-position: center center;
  padding: 70px 0 40px 0;
}

/* line 10, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_counter.scss */
.counter_area .single_counter {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  /* line 10, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_counter.scss */
  .counter_area .single_counter {
    text-align: center;
  }
}

/* line 15, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_counter.scss */
.counter_area .single_counter h3 {
  color: #FF3414;
  font-weight: 500;
  font-size: 46px;
  margin-bottom: 5px;
}

/* line 26, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_counter.scss */
.counter_area .single_counter p {
  color: #596672;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area {
  padding-top: 108px;
  padding-bottom: 83px;
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area.minus_padding {
  padding-top: 50px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
  .team_area {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 11, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team {
  margin-bottom: 30px;
}

/* line 13, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team .team_thumb {
  overflow: hidden;
  margin-bottom: 26px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
}

/* line 18, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team .team_thumb img {
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 23, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team .team_thumb .social_link {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 29, 56, 0.5);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  padding: 13px 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  transform: translateY(100%) scale(1.2);
  opacity: 0;
  visibility: hidden;
}

/* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team .team_thumb .social_link li {
  display: inline-block;
}

/* line 37, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team .team_thumb .social_link li a {
  font-size: 15px;
  color: #C7C7C7;
  margin: 0 6px;
}

/* line 41, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team .team_thumb .social_link li a:hover {
  color: #FDAE5C;
}

/* line 49, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team .team_info h3 {
  font-size: 22px;
  font-weight: 400;
  color: #2C2C2C;
  margin-bottom: 0;
}

/* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team .team_info p {
  font-size: 13px;
  font-weight: 300;
  color: #2C2C2C;
  margin-top: 6px;
  margin-bottom: 8px;
}

/* line 66, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team:hover .team_thumb img {
  width: 100%;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 71, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_team.scss */
.team_area .single_team:hover .team_thumb .social_link {
  transform: translateY(0%) scale(1);
  opacity: 1;
  visibility: visible;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote {
  padding-top: 0px;
  padding-bottom: 100px;
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote .form_wrap {
  margin-bottom: 30px;
}

/* line 6, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote .form_wrap h3 {
  font-size: 46px;
  font-weight: 300;
  color: #001D38;
  margin-bottom: 51px;
}

@media (max-width: 767px) {
  /* line 6, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
  .contact_form_quote .form_wrap h3 {
    font-size: 38px;
  }
}

/* line 16, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote .form_wrap form input, .contact_form_quote .form_wrap form textarea {
  height: 36px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #E8E8E8;
  font-size: 15px;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

/* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote .form_wrap form input::placeholder, .contact_form_quote .form_wrap form textarea::placeholder {
  color: #727272;
  font-weight: 300;
  font-size: 15px;
}

/* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote .form_wrap form textarea {
  height: 40px !important;
  resize: none;
  margin-top: 70px;
}

/* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote .form_wrap form button {
  width: 100%;
  text-align: center;
  padding: 13px;
}

/* line 42, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote .contact_thumb {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}

/* line 45, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_quote.scss */
.contact_form_quote .contact_thumb img {
  width: 100%;
}

/* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area {
  /* background-image: url(./img/banner/testmonial.png); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding: 122px 0 122px 0;
}

@media (max-width: 767px) {
  /* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
  .testimonial_area {
    padding: 50px 0;
  }
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .testmonial_active {
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 50px 60px 66px 60px;
}

@media (max-width: 767px) {
  /* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
  .testimonial_area .testmonial_active {
    padding: 40px 25px 40px 25px;
  }
}

/* line 22, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial p {
  color: #001D38;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin: 34px 0;
}

@media (min-width: 320px) and (max-width: 1500px) {
  /* line 28, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
  .testimonial_area .single_testmonial p br {
    display: none;
  }
}

/* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author {
  padding-bottom: 35px;
}

/* line 37, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author .thumb {
  width: 42px;
  height: 42px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: auto;
  margin: auto auto 15px auto;
}

/* line 44, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author h3 {
  color: #001D38;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

/* line 50, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author span {
  font-size: 13px;
  font-weight: 400;
  color: #596672;
}

/* line 57, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

/* line 61, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 0;
}

/* line 65, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div {
  left: 0;
}

/* line 69, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* line 73, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .owl-dots .owl-dot {
  width: 14px;
  height: 14px;
  background: #FFD6D0;
  display: inline-block;
  margin: 0 5px;
  border-radius: 50%;
  text-align: center;
}

/* line 81, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.testimonial_area .owl-dots .owl-dot.active {
  background: #FF3414;
}

/* line 89, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.Information_area {
  /* background-image: url(./img/banner/info_banner.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 162px 0;
}

@media (max-width: 767px) {
  /* line 89, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
  .Information_area {
    padding: 100px 0;
  }
}

/* line 99, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.Information_area .info_text h3 {
  font-size: 46px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 99, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
  .Information_area .info_text h3 {
    font-size: 30px;
  }
}

/* line 108, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.Information_area .info_text p {
  font-size: 15px;
  font-weight: 400;
  color: #E8E8E8;
  margin: 25px 0;
}

/* line 114, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.Information_area .info_text a.boxed-btn3 {
  padding: 14px 40px;
}

/* line 122, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.newsletter_form {
  position: relative;
  margin-bottom: 20px;
}

/* line 125, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.newsletter_form input {
  width: 100%;
  height: 45px;
  background: #fff;
  padding-left: 20px;
  font-size: 16px;
  color: #000;
  border: none;
}

/* line 133, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.newsletter_form input::placeholder {
  font-size: 16px;
  color: #919191;
}

/* line 138, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.newsletter_form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
  font-size: 14px;
  color: #fff;
  background: #A70000;
  padding: 10px;
  padding: 0 22px;
  cursor: pointer;
}

/* line 152, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_testmonial.scss */
.newsletter_text {
  font-size: 16px;
  color: #BABABA;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area {
  padding-top: 120px;
  padding-bottom: 120px;
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area .service_details_info {
  margin-bottom: 36px;
}

/* line 5, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area .service_details_info h3 {
  font-size: 36px;
  color: #001D38;
  font-weight: 400;
  margin-bottom: 16px;
}

/* line 11, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area .service_details_info p {
  font-size: 15px;
  line-height: 28px;
  color: #596672;
}

/* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area .service_details_left h3 {
  font-size: 22px;
  color: #001D38;
  font-weight: 500;
  margin-bottom: 40px;
}

/* line 26, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area .service_details_left .nav {
  display: block !important;
}

/* line 28, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area .service_details_left .nav a {
  padding: 0;
  border-bottom: 1px solid #EAEAEA;
  color: #001D38;
  font-size: 16px;
  padding: 25px 0;
  display: block;
}

/* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area .service_details_left .nav a:last-child {
  border-bottom: 0;
}

/* line 38, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.service_details_area .service_details_left .nav a.active, .service_details_area .service_details_left .nav a .nav-pills > a {
  color: #fff;
  background-color: transparent;
  color: #FF3414;
  font-size: 16px;
}

/* line 49, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.download_brochure {
  background: #F5FBFF;
  padding: 33px 45px;
}

@media (max-width: 767px) {
  /* line 49, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
  .download_brochure {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 49, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
  .download_brochure {
    display: block !important;
  }
}

@media (max-width: 767px) {
  /* line 58, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
  .download_brochure .download_left {
    display: block !important;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 58, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
  .download_brochure .download_left {
    display: block !important;
    margin-bottom: 20px;
  }
}

/* line 67, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.download_brochure .download_left .icon {
  margin-right: 30px;
}

@media (max-width: 767px) {
  /* line 67, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
  .download_brochure .download_left .icon {
    margin-bottom: 20px;
  }
}

/* line 74, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.download_brochure .download_left .download_text h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 0;
  color: #001D38;
}

/* line 80, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service_details.scss */
.download_brochure .download_left .download_text p {
  font-size: 14px;
  color: #596672;
  margin-bottom: 0;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_action_area {
  /* background-image: url(./img/banner/contact_bg.png); */
  position: relative;
  z-index: 0;
  padding: 138px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area {
    padding: 50px 0;
  }
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_action_area::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #001D38;
  opacity: .7;
  z-index: -1;
  content: '';
}

@media (max-width: 767px) {
  /* line 23, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .action_heading {
    text-align: center;
  }
}

/* line 27, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_action_area .action_heading h3 {
  font-size: 46px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 13px;
}

@media (max-width: 767px) {
  /* line 27, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .action_heading h3 {
    font-size: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 27, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .action_heading h3 {
    font-size: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 27, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .action_heading h3 {
    font-size: 30px;
  }
}

/* line 43, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_action_area .action_heading p {
  color: #EAEAEA;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 23, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .action_heading {
    text-align: center;
  }
}

/* line 53, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_action_area .call_add_action {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 53, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .call_add_action {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 53, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .call_add_action {
    text-align: right;
    margin-top: 0px;
  }
}

/* line 62, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_action_area .call_add_action span {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  margin-right: 25px;
}

@media (max-width: 767px) {
  /* line 62, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .call_add_action span {
    margin: 20px 0;
    display: block;
  }
}

/* line 72, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_action_area .call_add_action .boxed-btn3 {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  font-size: 26px;
  padding: 14px 42px;
  font-weight: 400;
}

@media (max-width: 767px) {
  /* line 72, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .call_add_action .boxed-btn3 {
    font-size: 14px;
    padding: 14px 30px;
    font-weight: 400;
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 72, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_action_area .call_add_action .boxed-btn3 {
    font-size: 14px;
    padding: 14px 30px;
    font-weight: 400;
    margin-top: 20px;
  }
}

/* line 89, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_action_area .call_add_action .boxed-btn3:hover {
  color: #000 !important;
  background: #fff !important;
  border-color: transparent;
}

/* line 101, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area {
  padding: 120px 0 72px 0;
}

@media (max-width: 767px) {
  /* line 101, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area {
    padding: 30px 0 40px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 101, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area {
    padding: 30px 0 40px 0;
  }
}

/* line 109, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .about_image {
  position: relative;
  z-index: 0;
}

/* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .about_image:before {
  position: absolute;
  background: #FF3414;
  right: -25px;
  bottom: -25px;
  content: '';
  z-index: -1;
  top: 10px;
  left: 10px;
}

@media (max-width: 767px) {
  /* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .about_image:before {
    display: none;
  }
}

@media (max-width: 767px) {
  /* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .about_image:before {
    display: none;
  }
}

/* line 128, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .about_image img {
  width: 100%;
}

/* line 133, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .about_draw img {
  width: 100%;
}

/* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .features_info {
  padding-left: 68px;
}

@media (max-width: 767px) {
  /* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info {
    padding-left: 30px;
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info {
    padding-left: 30px;
    padding-top: 0;
    padding-bottom: 40px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info {
    padding-left: 30px;
    padding-top: 0;
  }
}

/* line 161, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .features_info h3 {
  font-size: 46px;
  line-height: 60px;
  font-weight: 300;
  color: #2C2C2C;
}

@media (max-width: 767px) {
  /* line 161, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 161, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info h3 {
    font-size: 38px;
    line-height: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 161, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  /* line 178, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info h3 br {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 178, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info h3 br {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 178, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info h3 br {
    display: none;
  }
}

/* line 190, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .features_info p {
  font-size: 16px;
  line-height: 28px;
  color: #727272;
  margin-bottom: 22px;
  margin-top: 28px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 196, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info p br {
    display: none;
  }
}

/* line 202, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .features_info ul {
  margin-bottom: 56px;
}

@media (max-width: 767px) {
  /* line 202, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .chose_area .features_info ul {
    margin-bottom: 30px;
  }
}

/* line 207, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .features_info ul li {
  font-size: 16px;
  line-height: 28px;
  color: #727272;
  position: relative;
  padding-left: 28px;
  margin-bottom: 5px;
  font-weight: 400;
  z-index: 1;
}

/* line 216, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .features_info ul li::before {
  position: absolute;
  left: 0;
  /* background-image: url(./img/svg_icon/check.svg); */
  width: 16px;
  height: 16px;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* line 228, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.chose_area .features_info .boxed-btn3-line {
  padding: 14px 53px;
  font-size: 14px;
  font-weight: 500;
}

/* line 239, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location {
  background: #F5FBFF;
  padding: 100px 0;
}

@media (max-width: 767px) {
  /* line 239, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_location {
    padding: 50px 0;
  }
}

@media (max-width: 767px) {
  /* line 245, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
  .contact_location .location_left {
    margin-bottom: 20px;
  }
}

/* line 249, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location .location_left .logo {
  margin-bottom: 40px;
}

/* line 256, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location .location_left ul li {
  display: inline-block;
  margin-right: 6px;
}

/* line 259, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location .location_left ul li a {
  width: 38px;
  height: 38px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #3B5797;
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 38px;
  font-size: 15px;
}

/* line 270, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location .location_left ul li:nth-child(2) a {
  background: #DB4337;
}

/* line 273, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location .location_left ul li:nth-child(3) a {
  background: #00ACED;
}

/* line 276, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location .location_left ul li:nth-child(4) a {
  background: #BB0000;
}

/* line 283, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location .single_location h3 {
  font-size: 22px;
  color: #001D38;
  font-weight: 400;
  margin-bottom: 15px;
}

/* line 289, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact_action.scss */
.contact_location .single_location p {
  color: #596672;
  font-size: 14px;
  line-height: 28px;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details {
  position: relative;
  margin-top: -151px;
  padding-bottom: 90px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
  .property_details {
    padding-bottom: 40px;
  }
}

/* line 8, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact-section {
  padding: 41px 0 55px 0;
}

/* line 11, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .property_banner {
  margin-bottom: 60px;
}

/* line 13, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .property_banner .owl-carousel .owl-nav div {
  height: 40px;
  width: 40px;
  color: #707070;
  left: 50px;
  font-size: 13px;
  line-height: 40px;
  left: 50px;
  color: #001D38;
  background: #fff;
  border: none;
}

/* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .property_banner .owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 50px;
}

/* line 28, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .property_banner .owl-carousel .owl-nav div:hover {
  background: #FDAE5C;
  color: #fff;
}

/* line 33, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .property_banner .owl-carousel .owl-nav div.owl-prev i {
  position: relative;
  top: 0px;
  right: 1px;
}

/* line 42, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .details_info h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 25px;
  color: #001D38;
}

/* line 48, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .details_info p {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 13px;
  color: #727272;
}

/* line 57, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field h3 {
  font-size: 22px;
  font-weight: 500;
  color: #001D38;
  margin-bottom: 26px;
}

/* line 64, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field form {
  margin-bottom: 30px;
}

/* line 66, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field form input {
  height: 50px;
  background: #F5FBFF;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
  padding-left: 15px;
  margin-bottom: 20px;
}

/* line 75, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field form input::placeholder {
  color: #919191;
  font-size: 15px;
  font-weight: 400;
}

/* line 80, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field form input:focus {
  outline: none;
}

/* line 84, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field form textarea {
  height: 100px;
  background: #F5FBFF;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  resize: none;
  margin-bottom: 20px;
}

/* line 93, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field form textarea::placeholder {
  color: #919191;
  font-size: 15px;
  font-weight: 400;
}

/* line 98, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field form textarea:focus {
  outline: none;
}

/* line 102, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details .contact_field form .send_btn {
  background: #FDAE5C;
  color: #fff;
  text-align: center;
  padding: 6px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  display: block;
  cursor: pointer;
  width: 100%;
}

/* line 119, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner {
  background: #10273C;
  padding: 250px 0 210px 0;
}

@media (max-width: 767px) {
  /* line 119, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
  .property_details_banner {
    padding: 200px 0 210px 0;
  }
}

/* line 127, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .comfortable_apartment h4 {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
}

/* line 132, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .comfortable_apartment p {
  font-size: 13px;
  color: #C7C7C7;
}

/* line 135, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .comfortable_apartment p img {
  margin-right: 7px;
}

/* line 139, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .comfortable_apartment .quality_quantity {
  margin-top: 31px;
}

/* line 141, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .comfortable_apartment .quality_quantity .single_quantity {
  margin-right: 55px;
}

/* line 146, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .comfortable_apartment .quality_quantity .single_quantity span {
  display: block;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  margin-top: 13px;
}

/* line 156, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .prise_quantity {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 156, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
  .property_details_banner .prise_quantity {
    text-align: left;
    margin-top: 30px;
  }
}

/* line 162, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .prise_quantity h4 {
  font-size: 24px;
  font-weight: 500;
  color: #FF5748;
  margin-bottom: 20px;
}

/* line 168, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .prise_quantity a {
  color: #FDAE5C;
  border: 1px solid #FDAE5C;
  padding: 8px 34px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
}

/* line 178, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_property_details.scss */
.property_details_banner .prise_quantity a:hover {
  background: #FDAE5C;
  color: #fff;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area {
  /* background-image: url(./img/banner/Estimate_bg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 120px 0;
}

/* line 7, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area.overlay:before {
  background: #001D38;
  opacity: .94;
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .Estimate_info h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}

/* line 17, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .Estimate_info p {
  color: #C7C7C7;
  font-size: 16px;
  line-height: 28px;
  margin-top: 23px;
  margin-bottom: 33px;
}

/* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .Estimate_info .boxed-btn3 {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  padding: 9px 40px;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  /* line 11, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
  .Estimate_area .Estimate_info {
    margin-bottom: 30px;
  }
}

/* line 36, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .form .input_field input, .Estimate_area .form .input_field textarea {
  width: 100%;
  height: 50px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #667686;
  padding: 18px 15px;
  font-size: 14px;
  background: transparent;
  color: #fff;
  margin-bottom: 15px;
}

/* line 46, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .form .input_field input::placeholder, .Estimate_area .form .input_field textarea::placeholder {
  color: #C7C7C7;
  font-weight: 400;
  font-size: 14px;
}

/* line 51, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .form .input_field input:focus, .Estimate_area .form .input_field textarea:focus {
  outline: none;
}

/* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .form .input_field textarea {
  height: 105px;
}

/* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .form .nice-select {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  background: transparent;
  border: 1px solid #687786;
  margin-bottom: 15px;
  width: 100%;
  color: #C7C7C7;
}

/* line 82, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .form .nice-select .option {
  color: #000;
}

/* line 86, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .form .boxed-btn3-line {
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
}

/* line 93, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_Estimate_area.scss */
.Estimate_area .nice-select::after {
  content: "\e64b";
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 30px;
  top: 4px;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: 'themify';
  color: #ddd;
  font-size: 12px;
}

/* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion button {
  font-size: 16px;
  color: #0f2137;
  font-weight: 500;
  font-weight: 500;
}

@media (max-width: 767px) {
  /* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
  #accordion button {
    font-size: 14px;
  }
}

/* line 11, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion .card {
  margin-bottom: 15px;
  border-radius: 0;
  border-radius: 0 !important;
  margin-bottom: 30px;
}

/* line 16, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion .card .card-header {
  background-color: transparent;
  padding: 4px 14px;
}

@media (max-width: 767px) {
  /* line 16, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
  #accordion .card .card-header {
    padding: 4px 0;
  }
}

/* line 23, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion .card .card-body {
  font-size: 16px;
  line-height: 28px;
  color: #888888;
  padding: 10px 30px 32px;
}

/* line 29, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion .card .collapse {
  border-bottom: 1px solid #d7dbe3;
}

/* line 31, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion .card .collapse.show {
  border-bottom: 1px solid transparent;
}

/* line 39, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion h5 {
  position: relative;
  z-index: 1;
}

/* line 65, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion h5 button.btn.btn-link:after {
  position: absolute;
  content: "\e648";
  top: 9px;
  right: 12px;
  height: auto;
  font-family: 'themify';
  color: #FF3414;
  font-size: 14px;
  left: -24px;
}

@media (max-width: 767px) {
  /* line 65, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
  #accordion h5 button.btn.btn-link:after {
    right: 10px;
  }
}

/* line 81, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion h5 button.btn.btn-link.collapsed:after {
  content: "\e64b";
  color: #0f2137;
  font-size: 14px;
}

/* line 88, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion h5 button i {
  height: 36px;
  width: 36px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  margin-right: 20px;
  color: #fff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 767px) {
  /* line 88, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
  #accordion h5 button i {
    display: none;
  }
}

/* line 104, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion h5 button.btn.btn-link.collapsed i {
  background: #f4f7f9;
  color: #0f2137;
}

@media (max-width: 767px) {
  /* line 104, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
  #accordion h5 button.btn.btn-link.collapsed i {
    display: none;
  }
}

/* line 116, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion .btn-link:hover {
  color: #182028;
  text-decoration: none;
}

/* line 120, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion.scss */
#accordion .btn {
  text-align: left !important;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area {
  padding-top: 40px;
  padding-bottom: 30px;
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .accordion_thumb {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 68px;
}

@media (max-width: 767px) {
  /* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
  .accordion_area .accordion_thumb {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
  .accordion_area .accordion_thumb {
    padding-left: 0;
  }
}

/* line 14, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .accordion_thumb img {
  width: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask h3 {
  font-size: 36px;
  font-weight: 300;
  color: #001D38;
  margin-bottom: 17px;
}

@media (max-width: 767px) {
  /* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
  .accordion_area .faq_ask h3 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
  .accordion_area .faq_ask h3 {
    font-size: 30px;
  }
}

/* line 33, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: .25rem;
  border-bottom: 1px solid #E8E8E8;
}

/* line 50, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .card .card-header {
  background-color: transparent;
  padding: 4px 14px;
  border: none;
}

/* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion button {
  font-size: 15px;
  color: #001D38 !important;
  font-weight: 300 !important;
}

@media (max-width: 767px) {
  /* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
  .accordion_area .faq_ask #accordion button span {
    display: none;
  }
}

/* line 65, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .card .card-header {
  background-color: transparent;
  padding: 4px 30px;
}

/* line 80, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .card .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #727272;
  padding: 10px 30px 32px;
  font-weight: 400;
  line-height: 28px;
}

/* line 90, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .btn-link.focus, .accordion_area .faq_ask #accordion .btn-link:focus {
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
  color: #FF3414 !important;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
#slider {
  margin: 0px;
  margin: 0px;
  height: 2px;
  background: #B18351;
  height: 2px;
  top: 19px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
  #slider {
    margin-top: 20px;
    margin-bottom: 30px;
    top: 0;
  }
}

/* line 14, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.value {
  position: absolute;
  top: 30px;
  left: 50%;
  margin: 0 0 0 -20px;
  width: 40px;
  text-align: center;
  display: block;
  /* optional */
  font-weight: normal;
  font-family: Verdana,Arial,sans-serif;
  font-size: 14px;
  color: #333;
}

/* line 31, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.price-range-both.value {
  width: 100px;
  margin: 0 0 0 -50px;
  top: 26px;
}

/* line 37, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.price-range-both {
  display: none;
}

/* line 41, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.value i {
  font-style: normal;
}

/* line 44, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
body div.ui-slider-range.ui-widget-header {
  background: #F44336;
}

/* line 47, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  background: #2ecaf9 !important;
}

/* line 50, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background: #2ecaf9 !important;
  border-radius: 50%;
  font-size: 21px;
  border: none !important;
  box-shadow: 0 0 7px #306473;
}

/* line 56, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.ui-state-default:focus, .ui-widget-content .ui-state-default:focus, .ui-widget-header .ui-state-default:focus {
  outline: none;
}

/* line 60, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
span.ui-slider-handle.ui-corner-all.ui-state-default:focus {
  outline: none;
}

/* line 64, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.property_form {
  padding: 30px;
  background: rgba(0, 28, 56, 0.6);
}

/* line 68, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.property_form .single-field.range_slider {
  width: 100%;
}

/* line 70, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.property_form .single-field.range_slider #slider {
  margin: 0 !important;
}

/* line 79, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider {
  width: 100%;
}

/* line 107, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider .ui-state-default, .single_field.range_slider .ui-widget-content .ui-state-default, .single_field.range_slider .ui-widget-header .ui-state-default {
  background: #FDAE5C;
  border-radius: 50%;
  font-size: 21px;
  border: none !important;
  box-shadow: none;
  width: 42px;
  height: 22px;
  border-radius: 4px;
}

/* line 117, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider .ui-state-default, .single_field.range_slider .ui-widget-content .ui-state-default, .single_field.range_slider .ui-widget-header .ui-state-default {
  background: #FDAE5C !important;
  border-radius: 5px;
  font-size: 21px;
  border: none !important;
  box-shadow: none;
}

/* line 124, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider .price-range-max.value, .single_field.range_slider .price-range-min.value {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}

/* line 129, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider .ui-widget-content {
  border: none;
  background: #B18351;
  color: #fff;
}

/* line 136, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider div.ui-slider-range.ui-widget-header {
  background: #B18351;
}

/* line 139, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider .value {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}

/* line 146, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider .value.price-range-min, .single_field.range_slider .price-range-max.value {
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0 0 0 -20px;
  width: 100%;
  text-align: center;
  display: block;
  font-weight: normal;
  font-family: Verdana,Arial,sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
}

/* line 160, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider .ui-slider-horizontal .ui-slider-handle {
  top: -10px;
  margin-left: -25px;
}

/* line 165, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider.range_slider label {
  display: block;
  font-size: 15px;
  color: #FDAE5C;
}

/* line 174, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider {
  width: 120px !important;
  margin: 0 30px;
}

/* line 179, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_prise_slider.scss */
.single_field.range_slider label {
  position: relative;
  left: -27px;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area {
  padding-top: 100px;
  margin-top: 138px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
  .case_details_area {
    padding-top: 100px;
    margin-top: 0px;
  }
}

/* line 8, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .border_bottom {
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 10px;
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .case_thumb img {
  width: 100%;
}

/* line 15, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_title {
  margin-bottom: 60px;
}

/* line 17, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_title span {
  font-size: 15px;
  color: #919191;
  font-weight: 400;
}

/* line 22, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_title h3 {
  font-size: 46px;
  font-weight: 300;
  color: #001D38;
}

@media (max-width: 767px) {
  /* line 22, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
  .case_details_area .details_title h3 {
    font-size: 28px;
  }
}

/* line 32, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_main_wrap .details_info {
  color: #001D38;
  font-weight: 400;
  font-size: 20px;
  margin-top: 54px;
  margin-bottom: 35px;
}

/* line 39, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_main_wrap .single_details {
  margin-bottom: 30px;
}

/* line 41, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_main_wrap .single_details span {
  font-weight: 500;
  font-size: 22px;
  color: #001D38;
}

/* line 46, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_main_wrap .single_details p {
  color: #727272;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}

/* line 53, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_main_wrap .single_details ul li {
  display: inline-block;
  margin-right: 4px;
}

@media (max-width: 767px) {
  /* line 53, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
  .case_details_area .details_main_wrap .single_details ul li {
    display: block;
    margin-right: 4px;
    margin: 0;
    margin: 10px 0;
    text-align: center;
  }
}

/* line 63, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_main_wrap .single_details ul li a {
  background: #3B5997;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  color: #fff;
  padding: 15px 28px;
  font-size: 16px;
}

@media (max-width: 767px) {
  /* line 63, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
  .case_details_area .details_main_wrap .single_details ul li a {
    display: block;
  }
}

/* line 76, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_main_wrap .single_details ul li:nth-child(2) a {
  background: #1DA1F2 !important;
}

/* line 79, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_case_details.scss */
.case_details_area .details_main_wrap .single_details ul li:nth-child(3) a {
  background: #B21D23 !important;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_transport.scss */
.transportaion_area {
  padding: 120px 0 90px 0;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_transport.scss */
  .transportaion_area {
    padding: 70px 0 40px 0;
  }
}

/* line 6, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_transport.scss */
.transportaion_area .single_transport {
  padding-right: 65px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  /* line 6, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_transport.scss */
  .transportaion_area .single_transport {
    padding-right: 0;
  }
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_transport.scss */
.transportaion_area .single_transport .icon {
  height: 50px;
}

/* line 15, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_transport.scss */
.transportaion_area .single_transport h3 {
  font-size: 22px;
  font-weight: 500;
  color: #001D38;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 23, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_transport.scss */
.transportaion_area .single_transport p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #919191;
}

/* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_transport.scss */
.transportaion_area .single_transport:hover h3 {
  color: #FF3414;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area {
  padding-top: 150px;
  padding-bottom: 150px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
  .gallery_area {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
  .gallery_area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .single-gallery {
  overflow: hidden;
  position: relative;
}

/* line 15, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .single-gallery img {
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

/* line 20, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .single-gallery .gallery_hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  background: rgba(40, 174, 96, 0.7);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
}

/* line 33, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .single-gallery .gallery_hover .hover_inner {
  display: table-cell;
  vertical-align: middle;
}

/* line 36, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .single-gallery .gallery_hover .hover_inner h3 {
  font-size: 22px;
  color: #fff;
  font-weight: 400;
}

/* line 41, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .single-gallery .gallery_hover .hover_inner span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

/* line 49, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .single-gallery:hover .gallery_hover {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  visibility: visible;
  opacity: 1;
}

/* line 54, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .single-gallery:hover img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.gallery_area .More_Works_btn {
  margin-top: 40px;
}

/* line 64, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.portfolio_details_area {
  padding-top: 280px;
  padding-bottom: 150px;
}

@media (max-width: 767px) {
  /* line 64, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
  .portfolio_details_area {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 64, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
  .portfolio_details_area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* line 76, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.portfolio_details_area .portfolio_details_thumb img {
  width: 100%;
}

/* line 81, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.portfolio_details_area .portfolio_details_content span {
  font-size: 14px;
  font-weight: 300;
  color: #919191;
}

/* line 86, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.portfolio_details_area .portfolio_details_content h3 {
  color: #2C2C2C;
  font-size: 36px;
  font-weight: 400;
  line-height: 55px;
  margin-top: 15px;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  /* line 86, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
  .portfolio_details_area .portfolio_details_content h3 {
    font-size: 25px;
    line-height: 40px;
  }
}

/* line 98, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.portfolio_details_area .portfolio_details_content h4 {
  font-size: 20px;
  font-weight: 400;
  color: #2C2C2C;
}

/* line 103, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.portfolio_details_area .portfolio_details_content p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #727272;
  line-height: 28px;
}

/* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.mt-50 {
  margin-top: 50px;
}

@media (max-width: 767px) {
  /* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
  .mt-50 {
    margin-top: 30px;
  }
}

/* line 118, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
.mb-50 {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  /* line 118, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_gallery.scss */
  .mb-50 {
    margin-bottom: 30px;
  }
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area {
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .offers_area {
    padding-bottom: 40px;
  }
}

/* line 6, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area.padding_top {
  padding-top: 200px;
}

@media (max-width: 767px) {
  /* line 6, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .offers_area.padding_top {
    padding-top: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 6, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .offers_area.padding_top {
    padding-top: 80px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 6, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .offers_area.padding_top {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  /* line 18, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .offers_area .single_offers {
    margin-bottom: 30px;
  }
}

/* line 22, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area .single_offers .about_thumb {
  overflow: hidden;
}

/* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area .single_offers .about_thumb img {
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area .single_offers h3 {
  font-size: 22px;
  font-weight: 400;
  color: #1F1F1F;
  margin-top: 32px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .offers_area .single_offers h3 {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 38, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .offers_area .single_offers h3 br {
    display: none;
  }
}

/* line 44, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area .single_offers ul {
  margin-top: 17px;
  margin-bottom: 30px;
}

/* line 47, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area .single_offers ul li {
  font-size: 16px;
  color: #4D4D4D;
  line-height: 28px;
  position: relative;
  z-index: 9;
  padding-left: 23px;
}

/* line 54, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area .single_offers ul li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #4D4D4D;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
}

/* line 67, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area .single_offers a {
  width: 100%;
  text-align: center;
}

/* line 73, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.offers_area .single_offers:hover .about_thumb img {
  width: 100%;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/* line 84, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.video_bg {
  /* background-image: url(./img/video/video.png); */
}

/* line 87, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.video_area {
  padding: 250px 0;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 767px) {
  /* line 87, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .video_area {
    padding: 100px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 87, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .video_area {
    padding: 100px 0;
  }
}

/* line 98, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.video_area .video_area_inner span {
  font-size: 14px;
  color: #fff;
}

/* line 102, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.video_area .video_area_inner h3 {
  font-size: 46px;
  color: #fff;
  line-height: 56px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 28px;
}

@media (max-width: 767px) {
  /* line 102, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .video_area .video_area_inner h3 {
    font-size: 30px;
  }
}

/* line 113, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.video_area .video_area_inner a {
  width: 60px;
  height: 60px;
  background: #fff;
  line-height: 60px;
  font-size: 15px;
  color: #009DFF;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* line 122, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.video_area .video_area_inner a i {
  position: relative;
  left: 2px;
}

/* line 131, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room {
  padding-top: 93px;
  display: block;
  overflow: hidden;
}

@media (max-width: 767px) {
  /* line 131, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .features_room {
    padding-top: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 131, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .features_room {
    padding-top: 0;
  }
}

/* line 142, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms {
  position: relative;
  width: 50%;
  float: left;
}

@media (max-width: 767px) {
  /* line 142, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .features_room .rooms_here .single_rooms {
    width: 100%;
    margin-bottom: 30px;
  }
}

/* line 149, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,000000+100 */
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #000000 77%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #000000 77%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #000000 77%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
  /* IE6-9 */
  z-index: 1;
  opacity: .5;
}

/* line 166, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms .room_thumb {
  position: relative;
  overflow: hidden;
}

/* line 170, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms .room_thumb img {
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

/* line 175, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms .room_thumb .room_heading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  padding: 60px 60px 47px 60px;
}

@media (max-width: 767px) {
  /* line 175, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .features_room .rooms_here .single_rooms .room_thumb .room_heading {
    padding: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 175, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .features_room .rooms_here .single_rooms .room_thumb .room_heading {
    padding: 20px;
  }
}

/* line 187, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms .room_thumb .room_heading span {
  font-size: 14px;
  color: #FFFFFF;
  margin-bottom: 9px;
  display: block;
  position: relative;
  z-index: 8;
}

/* line 195, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms .room_thumb .room_heading h3 {
  font-size: 30px;
  color: #fff;
  position: relative;
  z-index: 8;
}

@media (max-width: 767px) {
  /* line 195, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .features_room .rooms_here .single_rooms .room_thumb .room_heading h3 {
    font-size: 20px;
  }
}

/* line 204, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms .room_thumb .room_heading a {
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  z-index: 8;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
  opacity: 0;
  visibility: hidden;
}

/* line 213, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms .room_thumb .room_heading a:hover {
  color: #009DFF;
}

/* line 220, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms:hover img {
  width: 100%;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/* line 225, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.features_room .rooms_here .single_rooms:hover .room_heading a {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

/* line 238, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.forQuery {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media (max-width: 767px) {
  /* line 238, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .forQuery {
    padding: 60px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 238, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .forQuery {
    padding: 100px 0;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 238, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .forQuery {
    padding: 100px 0;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 238, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .forQuery {
    padding: 100px 0;
  }
}

/* line 253, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.forQuery .Query_border {
  border: 1px solid #BABABA;
  padding: 38px 50px;
}

@media (max-width: 767px) {
  /* line 253, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .forQuery .Query_border {
    padding: 20px 20px;
  }
}

/* line 259, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.forQuery .Query_border .Query_text {
  text-align: left;
}

/* line 262, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.forQuery .Query_border p {
  font-size: 30px;
  color: #1F1F1F;
  font-weight: 400;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 262, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .forQuery .Query_border p {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 262, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .forQuery .Query_border p {
    font-size: 18px;
  }
}

/* line 276, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.forQuery .Query_border .phone_num {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 276, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .forQuery .Query_border .phone_num {
    text-align: center;
  }
}

/* line 281, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.forQuery .Query_border .phone_num a {
  background: #009DFF;
  color: #fff;
  padding: 12px 53px;
  border-radius: 30px;
  display: inline-block;
  font-size: 18px;
  border: 1px solid transparent;
}

/* line 289, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.forQuery .Query_border .phone_num a:hover {
  color: #009DFF;
  border: 1px solid #009DFF;
  background: #fff;
}

/* line 301, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.instragram_area {
  display: block;
  overflow: hidden;
}

/* line 310, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.instragram_area .single_instagram {
  width: 20%;
  float: left;
  position: relative;
  overflow: hidden;
}

@media (max-width: 767px) {
  /* line 310, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .instragram_area .single_instagram {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 310, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
  .instragram_area .single_instagram {
    width: 50%;
  }
}

/* line 322, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.instragram_area .single_instagram img {
  width: 100%;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

/* line 327, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.instragram_area .single_instagram .ovrelay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(-80%);
  -moz-transform: translateX(-80%);
  -ms-transform: translateX(-80%);
  transform: translateX(-80%);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

/* line 338, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.instragram_area .single_instagram .ovrelay a {
  color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  text-align: center;
  font-size: 34px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* line 351, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.instragram_area .single_instagram:hover .ovrelay {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  opacity: 1;
  visibility: visible;
}

/* line 356, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.instragram_area .single_instagram:hover img {
  -webkit-transform: scaleX(1.1);
  -moz-transform: scaleX(1.1);
  -ms-transform: scaleX(1.1);
  transform: scaleX(1.1);
}

/* line 367, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
#test-form .white-popup-block .popup_inner .gj-datepicker span {
  color: red;
}

/* line 372, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
#test-form .white-popup-block input {
  width: 100%;
  height: 50px;
}

/* line 379, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.gj-datepicker input {
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  padding: 17px;
  font-size: 12px;
  color: #919191;
  margin-bottom: 20px;
}

/* line 388, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.gj-datepicker-md [role="right-icon"] {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 14px;
  color: #919191;
  margin-right: 15px;
  top: 16px;
}

/* line 397, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_offers.scss */
.gj-picker-md {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px;
  padding: 20px;
  border: 1px solid #E0E0E0;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer {
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #fff;
}

/* line 7, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top {
  padding-top: 100px;
  padding-bottom: 110px;
}

@media (max-width: 767px) {
  /* line 7, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
  .footer .footer_top {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  /* line 14, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 14, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

/* line 21, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .footer_title {
  font-size: 18px;
  font-weight: 500;
  color: #001D38;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 21, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget .footer_title {
    margin-bottom: 20px;
  }
}

/* line 31, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .footer_logo {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 31, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget .footer_logo {
    margin-bottom: 20px;
  }
}

/* line 41, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p {
  color: #596672;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
}

/* line 47, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p a {
  color: #596672;
}

/* line 49, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p a:hover {
  color: #FF3414;
}

/* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text {
  font-size: 16px;
  color: #B2B2B2;
  margin-bottom: 23px;
  font-weight: 400;
  line-height: 28px;
}

/* line 61, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain {
  color: #B2B2B2;
  font-weight: 400;
}

/* line 64, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain:hover {
  color: #FF3414;
  border-bottom: 1px solid #FF3414;
}

/* line 70, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a {
  font-weight: 500;
  color: #B2B2B2;
}

/* line 74, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a:hover {
  color: #FF3414;
  border-bottom: 1px solid #FF3414;
}

/* line 78, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a.first {
  margin-bottom: 10px;
}

/* line 86, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li {
  color: #596672;
  font-size: 14px;
  line-height: 42px;
}

/* line 90, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li a {
  color: #596672;
  font-weight: 400;
}

/* line 93, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li a:hover {
  color: #FF3414;
}

/* line 99, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form {
  position: relative;
  margin-bottom: 20px;
}

/* line 102, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input {
  width: 100%;
  height: 50px;
  background: #fff;
  padding-left: 20px;
  font-size: 15px;
  color: #000;
  border: none;
  border: 1px solid #596672;
  border-radius: 30px;
  width: 100%;
}

/* line 113, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input::placeholder {
  font-size: 15px;
  color: #596672;
}

/* line 117, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input:focus {
  outline: none;
}

/* line 121, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  border: none;
  font-size: 14px;
  color: #fff;
  background: #FF3414;
  padding: 10px;
  padding: 0 22px;
  cursor: pointer;
  border-radius: 30px;
  top: 5px;
  right: 5px;
  font-size: 13px;
  font-weight: 500;
}

/* line 140, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_text {
  font-size: 13px;
  color: #596672;
  line-height: 26px;
}

/* line 147, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .copy-right_text {
  padding-bottom: 30px;
}

/* line 149, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .copy-right_text .footer_border {
  border-top: 1px solid #E8E8E8;
  padding-bottom: 30px;
}

/* line 153, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .copy-right_text .copy_right {
  font-size: 15px;
  color: #596672;
  margin-bottom: 0;
  font-weight: 500;
}

@media (max-width: 767px) {
  /* line 153, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
  .footer .copy-right_text .copy_right {
    font-size: 13px;
  }
}

/* line 161, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .copy-right_text .copy_right a {
  color: #FF3414;
}

/* line 166, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .socail_links {
  margin-top: 47px;
}

@media (max-width: 767px) {
  /* line 166, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
  .footer .socail_links {
    margin-top: 30px;
  }
}

/* line 172, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .socail_links ul li {
  display: inline-block;
}

/* line 175, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .socail_links ul li a {
  font-size: 15px;
  color: #C3B2F0;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  background: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 40px !important;
  margin-right: 7px;
  color: #FFFFFF;
  line-height: 40px !important;
  border: 1px solid #E8E8E8;
  color: #E8E8E8;
}

/* line 190, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_footer.scss */
.footer .socail_links ul li a:hover {
  color: #fff !important;
  background: #FF3414;
  border-color: transparent;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_bg_1 {
  /* background-image: url(./img/banner/bradcam.png); */
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_bg_2 {
  /* background-image: url(./img/banner/bradcam2.png); */
}

/* line 7, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_area {
  background-size: cover;
  background-position: center center;
  padding: 115px 0 115px 0;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
}

/* line 15, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_area.bradcam_area2 {
  padding: 265px 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 7, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
  .bradcam_area {
    padding: 80px 0;
  }
}

/* line 21, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_area::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #001D38;
  opacity: .5;
  z-index: -1;
  content: '';
}

@media (max-width: 767px) {
  /* line 7, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
  .bradcam_area {
    padding: 150px 0;
  }
}

/* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_area h3 {
  font-size: 60px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  /* line 35, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
  .bradcam_area h3 {
    font-size: 30px;
  }
}

/* line 45, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_area p {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
}

/* line 50, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_area p a {
  color: #fff;
}

/* line 52, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.bradcam_area p a:hover {
  color: #fff;
}

/* line 59, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box {
  background: #fff;
  display: inline-block;
  z-index: 9;
  width: 558px;
}

/* line 66, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box .boxed-btn3 {
  width: 100%;
  text-transform: capitalize;
}

/* line 70, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box .popup_header {
  background: #F5FBFF;
  padding: 28px 0;
  display: block;
}

/* line 74, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box .popup_header h3 {
  text-align: center;
  font-size: 20px;
  color: #2C2C2C;
  margin-bottom: 0;
  font-weight: 400;
}

/* line 82, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box .custom_form {
  padding: 60px 68px;
}

/* line 86, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box input {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid #C7C7C7;
  padding: 15px 0;
  margin-bottom: 20px;
}

/* line 93, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box input::placeholder {
  color: #919191;
  font-weight: 400;
}

/* line 97, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box input:focus {
  outline: none;
}

/* line 101, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box textarea {
  width: 100%;
  border: none;
  margin-top: 112px;
  border-bottom: 1px solid #C7C7C7;
  padding: 15px 0;
  margin-bottom: 20px;
  height: 55px;
  resize: none;
  margin-bottom: 40px;
}

/* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box textarea::placeholder {
  color: #919191;
  font-weight: 400;
}

/* line 116, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box textarea:focus {
  outline: none;
}

/* line 120, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box .nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  /* border-radius: 5px; */
  border: solid 1px #E2E2E2;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  width: 100% !important;
  /* height: 42px; */
  line-height: 50px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  border-radius: 0;
  margin-bottom: 30px;
  height: 50px !important;
  font-size: 16px;
  font-weight: 400;
  color: #919191;
}

/* line 154, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box .nice-select::after {
  content: "\f0d7";
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 17px;
  top: 3px;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: fontawesome;
  color: #919191;
  font-size: 15px;
}

/* line 169, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box .nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  height: 200px;
  overflow-y: scroll;
}

/* line 178, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.popup_box .nice-select.list {
  height: 200px;
  overflow-y: scroll;
}

/* line 184, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
#test-form {
  display: inline-block;
  margin: auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* line 192, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
#test-form .mfp-close-btn-in .mfp-close {
  color: #333;
  display: none !important;
}

/* line 197, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
#test-form button.mfp-close {
  display: none !important;
}

/* line 202, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_bradcam.scss */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000000;
  opacity: .7;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area {
  background: #F5FBFF;
  padding-top: 120px;
  padding-bottom: 90px;
}

/* line 5, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department {
  background: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 6px 10px rgba(0, 0, 0, 0.04);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.04);
}

/* line 9, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_thumb {
  overflow: hidden;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

/* line 13, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_thumb img {
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 19, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_content {
  padding: 22px 30px 24px 30px;
}

/* line 21, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_content h3 {
  margin-bottom: 0;
}

/* line 23, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_content h3 a {
  font-size: 22px;
  color: #1F1F1F;
  line-height: 33px;
  font-weight: 500;
}

/* line 28, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_content h3 a:hover {
  color: #5DB2FF;
}

/* line 33, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_content p {
  font-size: 16px;
  line-height: 28px;
  color: #727272;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* line 40, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_content a.learn_more {
  color: #5DB2FF;
  font-size: 16px;
}

/* line 43, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department .department_content a.learn_more:hover {
  text-decoration: underline;
}

/* line 50, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_department.scss */
.our_department_area .single_department:hover .department_thumb img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

/* line 3, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_tesmonial.scss */
.testmonial_area {
  padding-top: 0px;
  padding-bottom: 150px;
}

@media (max-width: 767px) {
  /* line 3, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_tesmonial.scss */
  .testmonial_area {
    padding-bottom: 50px;
  }
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_tesmonial.scss */
.testmonial_area .slider-nav div img {
  width: 100%;
}

/* line 18, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_tesmonial.scss */
.testmonial_area .single_slider p {
  font-size: 20px;
  line-height: 32px;
  color: #727272;
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 24px;
}

/* line 26, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_tesmonial.scss */
.testmonial_area .single_slider h4 {
  font-size: 16px;
  color: #2C2C2C;
  font-weight: 400;
  text-transform: uppercase;
}

/* line 32, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_tesmonial.scss */
.testmonial_area .single_slider span {
  color: #727272;
  font-size: 14px;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area {
  background: #F5FBFF;
  padding: 120px 0 90px 0;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
  .service_area {
    padding: 50px 0 20px 0;
  }
}

/* line 7, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service {
  margin-bottom: 30px;
}

/* line 9, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service .thumb {
  overflow: hidden;
  position: relative;
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service .thumb::before {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #FF3414;
  opacity: 0;
  position: absolute;
  z-index: 2;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  content: '';
}

/* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service .thumb img {
  width: 100%;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service .service_info {
  margin-top: 20px;
}

/* line 32, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service .service_info h3 {
  margin-bottom: 7px;
}

/* line 34, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service .service_info h3 a {
  color: #001D38;
  font-size: 22px;
  font-weight: 500;
}

/* line 40, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service .service_info p {
  font-size: 16px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 0;
}

/* line 50, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service:hover .service_info h3 a {
  color: #FF3414;
  text-decoration: underline;
}

/* line 57, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service:hover .thumb img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* line 60, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_service.scss */
.service_area .single_service:hover .thumb::before {
  opacity: .2;
}

/* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area {
  padding-top: 120px;
  padding-bottom: 120px;
}

/* line 4, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area.doctor_page {
  padding-bottom: 80px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
  .expert_doctors_area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .doctors_title h3 {
  font-size: 36px;
  font-weight: 500;
  color: #1F1F1F;
}

@media (max-width: 767px) {
  /* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
  .expert_doctors_area .doctors_title h3 {
    font-size: 24px;
  }
}

/* line 22, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert .expert_thumb {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

/* line 26, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert .expert_thumb img {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: 100%;
}

/* line 32, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert .experts_name {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: #F5FBFF;
  padding-top: 16px;
  padding-bottom: 18px;
}

/* line 37, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert .experts_name h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 43, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert .experts_name span {
  color: #919191;
  font-size: 13px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 51, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert:hover .expert_thumb img {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

/* line 55, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert:hover .experts_name {
  background: #5DB2FF;
}

/* line 57, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert:hover .experts_name h3 {
  color: #fff;
}

/* line 60, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .single_expert:hover .experts_name span {
  color: #fff;
}

/* line 67, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .owl-carousel .owl-nav div {
  background: transparent;
  height: 40px;
  left: 0px;
  text-align: center;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  width: 40px;
  color: #919191;
  background-color: transparent;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 40px;
  border: 1px solid #EEEEEE;
  left: auto;
  left: auto;
  top: -100px;
  right: 55px;
}

/* line 89, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 0;
}

/* line 94, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .owl-carousel .owl-nav div.owl-next i {
  position: relative;
  right: 0;
}

/* line 101, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_experts.scss */
.expert_doctors_area .owl-carousel .owl-nav div.owl-prev i {
  position: relative;
  top: 0px;
}

/* line 3, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
.Emergency_contact .single_emergency {
  padding: 91px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  /* line 3, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
  .Emergency_contact .single_emergency {
    padding: 40px;
    display: block !important;
  }
}

/* line 12, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
.Emergency_contact .single_emergency .info {
  margin-right: 30px;
}

/* line 14, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
.Emergency_contact .single_emergency .info h3 {
  font-size: 26px;
  font-weight: 500;
  color: #fff;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 14, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
  .Emergency_contact .single_emergency .info h3 {
    font-size: 20px;
  }
}

/* line 22, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
.Emergency_contact .single_emergency .info p {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}

/* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
.Emergency_contact .single_emergency .info_button a {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* line 36, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
.emergency_bg_1 {
  /* background-image: url(./img/banner/emergency-1.png); */
}

/* line 39, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_emergency_contact.scss */
.emergency_bg_2 {
  /* background-image: url(./img/banner/emergency-2.png); */
}

/*=================== contact banner start ====================*/
/* line 3, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 10, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-info {
  margin-bottom: 25px;
}

/* line 13, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-info__icon {
  margin-right: 20px;
}

/* line 16, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-info__icon i, .contact-info__icon span {
  color: #8f9195;
  font-size: 27px;
}

/* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-size: 16px;
  color: #2a2a2a;
}

/* line 30, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-info .media-body h3 a:hover {
  color: #ff5e13;
}

/* line 36, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-info .media-body p {
  color: #8a8a8a;
}

/*=================== contact banner end ====================*/
/*=================== contact form start ====================*/
/* line 45, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* line 53, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.form-contact label {
  font-size: 14px;
}

/* line 57, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.form-contact .form-group {
  margin-bottom: 30px;
}

/* line 61, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.form-contact .form-control {
  border: 1px solid #e5e6e9;
  border-radius: 0px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 69, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.form-contact .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 74, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.form-contact .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 80, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.form-contact textarea {
  border-radius: 0px;
  height: 100% !important;
}

/*=================== contact form end ====================*/
/* Contact Success and error Area css
============================================================================================ */
/* line 97, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

/* line 106, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

/* line 121, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

/* line 126, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/* line 133, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-section {
  padding: 130px 0 100px;
}

@media (max-width: 991px) {
  /* line 133, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
  .contact-section {
    padding: 70px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 133, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
  .contact-section {
    padding: 80px 0 50px;
  }
}

/* line 141, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-section .btn_2 {
  background-color: #191d34;
  padding: 18px 60px;
  border-radius: 50px;
  margin-top: 0;
}

/* line 146, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_contact.scss */
.contact-section .btn_2:hover {
  background-color: #ff5e13;
}

/* line 75, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0;
}

/* line 80, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

/* line 85, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
b,
sup,
sub,
u,
del {
  color: #ff5e13;
}

/* line 93, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
h1 {
  font-size: 36px;
}

/* line 97, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
h2 {
  font-size: 30px;
}

/* line 101, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
h3 {
  font-size: 24px;
}

/* line 105, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
h4 {
  font-size: 18px;
}

/* line 109, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
h5 {
  font-size: 16px;
}

/* line 113, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
h6 {
  font-size: 14px;
}

/* line 117, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
}

/* line 127, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #828bb2;
}

/* line 137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.button-area {
  background: #fff;
}

/* line 138, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

/* line 146, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

/* line 149, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

/* line 155, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* line 166, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn:focus {
  outline: none;
}

/* line 169, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

/* line 173, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.large {
  line-height: 45px;
}

/* line 176, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.medium {
  line-height: 30px;
}

/* line 179, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.small {
  line-height: 25px;
}

/* line 182, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.radius {
  border-radius: 3px;
}

/* line 185, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.circle {
  border-radius: 20px;
}

/* line 188, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 195, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.arrow span {
  margin-left: 10px;
}

/* line 199, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.default {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 203, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 208, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 211, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.default-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 217, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.primary {
  color: #fff;
  background: #ff5e13;
  border: 1px solid transparent;
}

/* line 221, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.primary:hover {
  color: #ff5e13;
  border: 1px solid #ff5e13;
  background: #fff;
}

/* line 227, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.primary-border {
  color: #ff5e13;
  border: 1px solid #ff5e13;
  background: #fff;
}

/* line 231, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.primary-border:hover {
  color: #fff;
  background: #ff5e13;
  border: 1px solid transparent;
}

/* line 237, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 241, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 247, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 251, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 257, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 261, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 267, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 271, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 277, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 281, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 287, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 291, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 297, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 301, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 307, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 311, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 317, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.link {
  color: #415094;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

/* line 322, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.link:hover {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 328, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.link-border {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

/* line 333, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.link-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 339, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

/* line 347, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #ff5e13;
}

/* line 353, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table-wrap {
  overflow-x: scroll;
}

/* line 357, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

/* line 361, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

/* line 365, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .country {
  width: 28.07%;
}

/* line 368, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .visit {
  width: 19.74%;
}

/* line 371, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

/* line 375, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-head {
  display: flex;
}

/* line 377, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #415094;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

/* line 387, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

/* line 391, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

/* line 399, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .country img {
  margin-right: 15px;
}

/* line 404, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

/* line 408, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

/* line 411, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

/* line 414, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

/* line 417, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

/* line 420, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

/* line 423, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

/* line 426, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

/* line 429, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

/* line 432, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

/* line 441, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
}

/* line 449, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.list-style {
  width: 14px;
  height: 14px;
}

/* line 455, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

/* line 459, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #ff5e13;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

/* line 473, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.ordered-list {
  margin-left: 30px;
}

/* line 475, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 480, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.ordered-list li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 488, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 494, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.ordered-list-alpha li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 502, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 508, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.ordered-list-roman li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 515, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 523, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-input:focus {
  outline: none;
}

/* line 528, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.input-group-icon {
  position: relative;
}

/* line 530, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

/* line 535, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.input-group-icon .icon i {
  color: #797979;
}

/* line 540, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.input-group-icon .single-input {
  padding-left: 45px;
}

/* line 545, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

/* line 555, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-textarea:focus {
  outline: none;
}

/* line 560, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 568, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-input-primary:focus {
  outline: none;
  border: 1px solid #ff5e13;
}

/* line 574, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 582, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

/* line 588, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 596, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

/* line 602, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 609, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 619, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff5e13;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 632, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-switch input:checked + label {
  left: 19px;
}

/* line 639, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 646, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 655, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 663, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/* line 677, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 693, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-switch input:checked + label:after {
  left: 19px;
}

/* line 696, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-switch input:checked + label:before {
  background: #ff5e13;
}

/* line 704, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 711, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 720, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 728, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

/* line 742, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 758, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-switch input:checked + label:after {
  left: 19px;
}

/* line 761, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

/* line 769, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 776, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 785, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 798, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-checkbox input:checked + label {
  /* background: url(./img/elements/primary-check.png) no-repeat center center/cover; */
  border: none;
}

/* line 806, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 813, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 822, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 835, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-checkbox input:checked + label {
  /* background: url(./img/elements/success-check.png) no-repeat center center/cover; */
  border: none;
}

/* line 843, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 850, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 859, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 871, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 876, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-checkbox input:checked + label {
  /* background: url(./img/elements/disabled-check.png) no-repeat center center/cover; */
  border: none;
}

/* line 884, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 891, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 900, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 913, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.primary-radio input:checked + label {
  /* background: url(./img/elements/primary-radio.png) no-repeat center center/cover; */
  border: none;
}

/* line 921, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 928, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 937, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 950, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.confirm-radio input:checked + label {
  /* background: url(./img/elements/success-radio.png) no-repeat center center/cover; */
  border: none;
}

/* line 958, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 965, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 974, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 986, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 991, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.disabled-radio input:checked + label {
  /* background: url(./img/elements/disabled-radio.png) no-repeat center center/cover; */
  border: none;
}

/* line 999, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-select {
  height: 40px;
}

/* line 1001, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}

/* line 1008, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1015, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

/* line 1022, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-select .nice-select .list .option.selected {
  color: #ff5e13;
  background: transparent;
}

/* line 1026, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-select .nice-select .list .option:hover {
  color: #ff5e13;
  background: transparent;
}

/* line 1033, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1037, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.default-select .nice-select::after {
  right: 20px;
}

/* line 1042, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.form-select {
  height: 40px;
  width: 100%;
}

/* line 1045, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

/* line 1053, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1060, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

/* line 1067, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.form-select .nice-select .list .option.selected {
  color: #ff5e13;
  background: transparent;
}

/* line 1071, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.form-select .nice-select .list .option:hover {
  color: #ff5e13;
  background: transparent;
}

/* line 1078, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1082, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.form-select .nice-select::after {
  right: 20px;
}

/* line 1086, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.mt-10 {
  margin-top: 10px;
}

/* line 1089, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

/* line 1093, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.mb-30 {
  margin-bottom: 30px;
}

/* line 1096, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.mt-30 {
  margin-top: 30px;
}

/* line 1099, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_elements.scss */
.switch-wrap {
  margin-bottom: 10px;
}

/* Start Blog Area css
============================================================================================ */
/* line 5, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.latest-blog-area .area-heading {
  margin-bottom: 70px;
}

/* line 10, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_area a {
  color: #666666 !important;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 14, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_area a:hover, .blog_area a :hover {
  background: -webkit-linear-gradient(131deg, #FF3414 0%, #FF3414 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog {
  overflow: hidden;
  margin-bottom: 30px;
}

/* line 28, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog:hover {
  box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1);
}

/* line 32, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .thumb {
  overflow: hidden;
  position: relative;
}

/* line 36, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .thumb:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 49, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog h4 {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 34px;
  margin-bottom: 25px;
}

/* line 56, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 66, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .date {
  color: #666666;
  text-align: left;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
}

/* line 74, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .tag {
  text-align: left;
  display: inline-block;
  float: left;
  font-size: 13px;
  font-weight: 300;
  margin-right: 22px;
  position: relative;
}

/* line 84, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .tag:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 10px;
  background: #acacac;
  right: -12px;
  top: 7px;
}

@media (max-width: 1199px) {
  /* line 74, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-blog .tag {
    margin-right: 8px;
  }
  /* line 98, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-blog .tag:after {
    display: none;
  }
}

/* line 104, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .likes {
  margin-right: 16px;
}

@media (max-width: 800px) {
  /* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-blog {
    margin-bottom: 30px;
  }
}

/* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .single-blog-content {
  padding: 30px;
}

/* line 116, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .single-blog-content .meta-bottom p {
  font-size: 13px;
  font-weight: 300;
}

/* line 121, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog .single-blog-content .meta-bottom i {
  color: #fdcb9e;
  font-size: 13px;
  margin-right: 7px;
}

@media (max-width: 1199px) {
  /* line 112, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-blog .single-blog-content {
    padding: 15px;
  }
}

/* line 135, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-blog:hover .thumb:after {
  opacity: .7;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 1199px) {
  /* line 143, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-blog h4 {
    transition: all 300ms linear 0s;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 14px;
    margin-bottom: 12px;
  }
  /* line 149, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-blog h4 a {
    font-size: 18px;
  }
}

/* line 157, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.full_image.single-blog {
  position: relative;
}

/* line 160, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.full_image.single-blog .single-blog-content {
  position: absolute;
  left: 35px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (min-width: 992px) {
  /* line 160, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .full_image.single-blog .single-blog-content {
    bottom: 100px;
  }
}

/* line 179, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.full_image.single-blog h4 {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: none;
  padding-bottom: 5px;
}

/* line 185, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.full_image.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 195, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.full_image.single-blog .date {
  color: #fff;
}

/* line 200, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.full_image.single-blog:hover .single-blog-content {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* End Blog Area css
============================================================================================ */
/* Latest Blog Area css
============================================================================================ */
/* line 224, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.l_blog_item .l_blog_text .date {
  margin-top: 24px;
  margin-bottom: 15px;
}

/* line 228, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.l_blog_item .l_blog_text .date a {
  font-size: 12px;
}

/* line 234, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.l_blog_item .l_blog_text h4 {
  font-size: 18px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
  padding-bottom: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 247, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.l_blog_item .l_blog_text p {
  margin-bottom: 0px;
  padding-top: 20px;
}

/* End Latest Blog Area css
============================================================================================ */
/* Causes Area css
============================================================================================ */
/* line 263, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px;
}

/* line 267, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_slider .owl-dots .owl-dot {
  height: 14px;
  width: 14px;
  background: #eeeeee;
  display: inline-block;
  margin-right: 7px;
}

/* line 274, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

/* line 285, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item {
  background: #fff;
}

/* line 288, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_img {
  position: relative;
}

/* line 291, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_img .c_parcent {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
}

/* line 299, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_img .c_parcent span {
  width: 70%;
  height: 3px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

/* line 307, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_img .c_parcent span:before {
  content: "75%";
  position: absolute;
  right: -10px;
  bottom: 0px;
  color: #fff;
  padding: 0px 5px;
}

/* line 320, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_text {
  padding: 30px 35px 40px 30px;
}

/* line 323, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_text h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

/* line 336, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_text p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 0px;
}

/* line 346, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_bottom a {
  width: 50%;
  border: 1px solid;
  text-align: center;
  float: left;
  line-height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

/* line 358, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.causes_item .causes_bottom a + a {
  border-color: #eeeeee;
  background: #fff;
  font-size: 14px;
}

/* End Causes Area css
============================================================================================ */
/*================= latest_blog_area css =============*/
/* line 374, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.latest_blog_area {
  background: #f9f9ff;
}

/* line 378, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-recent-blog-post {
  margin-bottom: 30px;
}

/* line 381, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-recent-blog-post .thumb {
  overflow: hidden;
}

/* line 384, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-recent-blog-post .thumb img {
  transition: all 0.7s linear;
}

/* line 389, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-recent-blog-post .details {
  padding-top: 30px;
}

/* line 392, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-recent-blog-post .details .sec_h4 {
  line-height: 24px;
  padding: 10px 0px 13px;
  transition: all 0.3s linear;
}

/* line 403, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-recent-blog-post .date {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/* line 410, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-recent-blog-post:hover img {
  transform: scale(1.23) rotate(10deg);
}

/* line 417, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center;
}

/* line 431, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.tags .tag_btn + .tag_btn {
  margin-left: 2px;
}

/*========= blog_categorie_area css ===========*/
/* line 438, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_categorie_area {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 900px) {
  /* line 438, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .blog_categorie_area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1100px) {
  /* line 438, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .blog_categorie_area {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

/* line 454, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer;
}

/* line 459, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.categories_post img {
  max-width: 100%;
}

/* line 463, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.categories_post .categories_details {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 34, 34, 0.75);
  color: #fff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 476, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.categories_post .categories_details h5 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

/* line 494, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.categories_post .categories_details p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
}

/* line 501, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.categories_post .categories_details .border_line {
  margin: 10px 0px;
  background: #fff;
  width: 100%;
  height: 1px;
}

/* line 510, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.categories_post:hover .categories_details {
  background: rgba(222, 99, 32, 0.85);
}

/*============ blog_left_sidebar css ==============*/
/* line 525, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_item {
  margin-bottom: 50px;
}

/* line 529, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_details {
  padding: 30px 0 20px 10px;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}

@media (min-width: 768px) {
  /* line 529, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .blog_details {
    padding: 60px 30px 35px 35px;
  }
}

/* line 537, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_details p {
  margin-bottom: 30px;
}

/* line 541, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_details a {
  color: #ff8b23;
}

/* line 544, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_details a:hover {
  color: #FF3414;
}

/* line 549, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_details h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  /* line 549, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .blog_details h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

/* line 563, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-info-link li {
  float: left;
  font-size: 14px;
}

/* line 567, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-info-link li a {
  color: #999999;
}

/* line 571, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-info-link li i,
.blog-info-link li span {
  font-size: 13px;
  margin-right: 5px;
}

/* line 577, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-info-link li::after {
  content: "|";
  padding-left: 10px;
  padding-right: 10px;
}

/* line 583, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-info-link li:last-child::after {
  display: none;
}

/* line 588, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-info-link::after {
  content: "";
  display: block;
  clear: both;
  display: table;
}

/* line 596, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_item_img {
  position: relative;
}

/* line 599, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_item_img .blog_item_date {
  position: absolute;
  bottom: -10px;
  left: 10px;
  display: block;
  color: #fff;
  background-color: #FF3414;
  padding: 8px 15px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  /* line 599, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .blog_item_img .blog_item_date {
    bottom: -20px;
    left: 40px;
    padding: 13px 30px;
  }
}

/* line 615, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_item_img .blog_item_date h3 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (min-width: 768px) {
  /* line 615, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .blog_item_img .blog_item_date h3 {
    font-size: 30px;
  }
}

/* line 627, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_item_img .blog_item_date p {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
}

@media (min-width: 768px) {
  /* line 627, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .blog_item_img .blog_item_date p {
    font-size: 18px;
  }
}

/* line 647, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
}

/* line 652, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .widget_title::after {
  content: "";
  display: block;
  padding-top: 15px;
  border-bottom: 1px solid #f0e9ff;
}

/* line 660, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .single_sidebar_widget {
  background: #fbf9ff;
  padding: 30px;
  margin-bottom: 30px;
}

/* line 664, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .single_sidebar_widget .btn_1 {
  margin-top: 0px;
}

/* line 672, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
  border-right: 0;
}

/* line 681, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .form-control::placeholder {
  color: #999999;
}

/* line 685, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 694, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 701, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .input-group button i,
.blog_right_sidebar .search_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 713, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
}

/* line 722, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control::placeholder {
  color: #999999;
}

/* line 726, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 735, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 742, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 755, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 1px solid #f0e9ff;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px;
}

/* line 760, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li:last-child {
  border-bottom: 0;
}

/* line 764, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #888888;
}

/* line 769, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li a p {
  margin-bottom: 0px;
}

/* line 774, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li + li {
  padding-top: 15px;
}

/* line 791, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px;
}

/* line 796, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
  transition: all 0.3s linear;
}

/* line 807, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
  color: #fff;
}

/* line 813, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
}

/* line 820, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item + .post_item {
  margin-top: 20px;
}

/* line 828, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block;
}

/* line 831, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li a {
  display: inline-block;
  border: 1px solid #eeeeee;
  background: #fff;
  padding: 4px 20px;
  margin-bottom: 8px;
  margin-right: 3px;
  transition: all 0.3s ease 0s;
  color: #888888;
  font-size: 13px;
}

/* line 842, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li a:hover {
  background: #FF3414;
  color: #fff !important;
  -webkit-text-fill-color: #fff;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 857, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .instagram_feeds .instagram_row {
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
}

/* line 863, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .instagram_feeds .instagram_row li {
  width: 33.33%;
  float: left;
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 15px;
}

/* line 963, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px;
}

/* line 984, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-pagination {
  margin-top: 80px;
}

/* line 988, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-pagination .page-link {
  font-size: 14px;
  position: relative;
  display: block;
  padding: 0;
  text-align: center;
  margin-left: -1px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 0 !important;
  color: #8a8a8a;
  border: 1px solid #f0e9ff;
  margin-right: 10px;
}

/* line 1005, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-pagination .page-link i,
.blog-pagination .page-link span {
  font-size: 13px;
}

/* line 1017, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-pagination .page-item.active .page-link {
  background-color: #fbf9ff;
  border-color: #f0e9ff;
  color: #888888;
}

/* line 1024, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.blog-pagination .page-item:last-child .page-link {
  margin-right: 0;
}

/*============ Start Blog Single Styles  =============*/
/* line 1049, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog_details {
  box-shadow: none;
  padding: 0;
}

/* line 1054, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .social-links {
  padding-top: 10px;
}

/* line 1057, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

/* line 1061, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .social-links li a {
  color: #cccccc;
  padding: 7px;
  font-size: 14px;
  transition: all 0.2s linear;
}

/* line 1074, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog_details {
  padding-top: 26px;
}

/* line 1077, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog_details p {
  margin-bottom: 20px;
  font-size: 15px;
}

/* line 1087, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .quote-wrapper {
  background: rgba(130, 139, 178, 0.1);
  padding: 15px;
  line-height: 1.733;
  color: #888888;
  font-style: italic;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  /* line 1087, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-post-area .quote-wrapper {
    padding: 30px;
  }
}

/* line 1101, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .quotes {
  background: #fff;
  padding: 15px 15px 15px 20px;
  border-left: 2px solid;
}

@media (min-width: 768px) {
  /* line 1101, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-post-area .quotes {
    padding: 25px 25px 25px 30px;
  }
}

/* line 1111, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .arrow {
  position: absolute;
}

/* line 1114, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

/* line 1121, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

/* line 1126, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top {
  padding-top: 15px;
  border-top: 1px solid #f0e9ff;
}

/* line 1130, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top p {
  margin-bottom: 0;
}

/* line 1134, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top .like-info {
  font-size: 14px;
}

/* line 1137, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1144, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top .comment-count {
  font-size: 14px;
}

/* line 1147, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1156, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top .social-icons li {
  display: inline-block;
  margin-right: 15px;
}

/* line 1160, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top .social-icons li:last-child {
  margin: 0;
}

/* line 1164, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
  font-size: 14px;
  color: #999999;
}

/* line 1182, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog-author {
  padding: 40px 30px;
  background: #fbf9ff;
  margin-top: 50px;
}

@media (max-width: 600px) {
  /* line 1182, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-post-area .blog-author {
    padding: 20px 8px;
  }
}

/* line 1191, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog-author img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 30px;
}

@media (max-width: 600px) {
  /* line 1191, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-post-area .blog-author img {
    margin-right: 15px;
    width: 45px;
    height: 45px;
  }
}

/* line 1204, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog-author a {
  display: inline-block;
}

/* line 1208, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog-author a:hover {
  color: #FF3414;
}

/* line 1213, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog-author p {
  margin-bottom: 0;
  font-size: 15px;
}

/* line 1218, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .blog-author h4 {
  font-size: 16px;
}

/* line 1225, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area {
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-top: 55px;
}

/* line 1230, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area p {
  margin-bottom: 0px;
}

/* line 1234, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area h4 {
  font-size: 18px;
  line-height: 25px;
}

/* line 1240, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left {
  text-align: left;
}

/* line 1243, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

/* line 1247, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1252, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1259, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

/* line 1264, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: .5;
}

@media (max-width: 767px) {
  /* line 1240, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

/* line 1275, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right {
  text-align: right;
}

/* line 1278, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

/* line 1282, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1287, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1294, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

/* line 1299, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: .5;
}

@media (max-width: 991px) {
  /* line 1307, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

/* line 1314, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area {
  background: transparent;
  border-top: 1px solid #eee;
  padding: 45px 0;
  margin-top: 50px;
}

@media (max-width: 414px) {
  /* line 1314, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .comments-area {
    padding: 50px 8px;
  }
}

/* line 1325, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area h4 {
  margin-bottom: 35px;
  font-size: 18px;
}

/* line 1332, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

/* line 1341, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area .comment-list {
  padding-bottom: 48px;
}

/* line 1344, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

/* line 1348, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  /* line 1354, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  /* line 1358, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  /* line 1362, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

/* line 1369, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area .thumb {
  margin-right: 20px;
}

/* line 1372, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area .thumb img {
  width: 70px;
  border-radius: 50%;
}

/* line 1378, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area .date {
  font-size: 14px;
  color: #999999;
  margin-bottom: 0;
  margin-left: 20px;
}

/* line 1385, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area .comment {
  margin-bottom: 10px;
  color: #777777;
  font-size: 15px;
}

/* line 1391, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comments-area .btn-reply {
  background-color: transparent;
  color: #888888;
  padding: 5px 18px;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

/* line 1408, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form {
  border-top: 1px solid #eee;
  padding-top: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
}

/* line 1416, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form .form-group {
  margin-bottom: 30px;
}

/* line 1420, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form h4 {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 22px;
}

/* line 1428, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 767px) {
  /* line 1428, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}

/* line 1437, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  /* line 1437, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
  .comment-form .email {
    padding-left: 0px;
  }
}

/* line 1445, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form .form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 1453, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 1458, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 1463, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form .form-control::placeholder {
  color: #777777;
}

/* line 1468, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form textarea {
  padding-top: 18px;
  border-radius: 12px;
  height: 100% !important;
}

/* line 1474, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

/* line 1480, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

/* line 1486, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

/* line 1492, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog.scss */
.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

/*============ End Blog Single Styles  =============*/
/**************** blog part css start ****************/
/* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part {
  margin-bottom: 140px;
}

@media (max-width: 576px) {
  /* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part {
    margin-bottom: 0px;
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part {
    margin-bottom: 0px;
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part {
    margin-bottom: 0px;
    padding: 0px 0px 70px;
  }
}

/* line 19, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
  font-style: inherit !important;
}

@media (max-width: 576px) {
  /* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 140px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 140px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 24, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 140px;
    margin-top: 20px;
  }
}

/* line 40, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card-img-top {
  border-radius: 0px;
}

/* line 43, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card {
  border: 0px solid transparent;
  border-radius: 0px;
  background-color: transparent;
  position: relative;
}

/* line 48, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .card-body {
  padding: 25px 10px 29px 40px;
  background-color: #fff;
  position: absolute;
  left: 20px;
  bottom: -140px;
  box-shadow: -7.552px 9.326px 20px 0px rgba(1, 84, 85, 0.1);
  border-radius: 10px;
}

@media (max-width: 576px) {
  /* line 48, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 15px;
    left: 10px;
    bottom: -140px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 48, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 15px;
    left: 10px;
    bottom: -140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 48, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 20px;
  }
}

/* line 72, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .card-body a {
  color: #FF3414;
  text-transform: uppercase;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

/* line 76, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .card-body a:hover {
  background: -webkit-linear-gradient(131deg, #ff7e5f 0%, #feb47b 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* line 83, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .dot {
  position: relative;
  padding-left: 20px;
}

/* line 86, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .dot:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 5px;
  left: 0;
  background-color: #FF3414;
  border-radius: 50%;
}

/* line 97, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card span {
  color: #8a8a8a;
  margin-bottom: 10px;
  display: inline-block;
  margin-top: 10px;
}

@media (max-width: 576px) {
  /* line 97, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 97, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 97, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 97, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

/* line 119, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card h5 {
  font-weight: 600;
  line-height: 1.5;
  font-size: 20px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  text-transform: capitalize;
  margin-bottom: 22px;
}

@media (max-width: 576px) {
  /* line 119, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 119, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 119, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 119, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

/* line 140, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card h5:hover {
  -webkit-transition: 0.8s;
  transition: 0.8s;
  background: -webkit-linear-gradient(131deg, #feb47b 0%, #ff7e5f 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: 1s;
}

/* line 150, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card ul li {
  display: inline-block;
  color: #8a8a8a;
  margin-right: 14px;
}

@media (max-width: 576px) {
  /* line 150, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 150, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 150, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 150, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

/* line 166, ../../Arafath/CL/Cl November/223. Transportation/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card ul li span {
  margin-right: 10px;
  font-size: 12px;
}

/*# sourceMappingURL=style.css.map */